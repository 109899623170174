
import { defineComponent } from 'vue'
import { StructuresNode } from 'types/interface/StructuresNode'
import useArchive from '@/composables/useArchive'
import ArchiveWrapper from '@/views/common/ArchiveWrapper.vue'

export default defineComponent({
  name: 'ExercisesArchive',

  components: {
    ArchiveWrapper,
  },

  setup() {
    const { archive, items } = useArchive()

    const cardActions = {
      type: 'exercises',
      action: (child: StructuresNode) => {
        return `/oppgave/${child.slugPath}`
      },
    }

    return {
      archive,
      items,
      cardActions,
    }
  },
})
