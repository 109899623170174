
import { defineComponent } from 'vue'

import { useBreadcrumbs } from '@/core'

export default defineComponent({
  name: 'Breadcrumbs',

  setup() {
    const { breadcrumbs } = useBreadcrumbs()

    return {
      breadcrumbs,
    }
  },
})
