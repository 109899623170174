import { ref, computed, onBeforeMount, watch } from 'vue'
import { StructuresNode } from 'types/interface/StructuresNode'
import { useRoute } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'

import { useStructures, useTenant, useBreadcrumbs } from '@/core'
import useTranslations from '@/composables/useTranslations'
import { ComposeArchive } from 'types/interface/Archive'

const useArchive = (): ComposeArchive => {
  const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs()
  const { tenant } = useTenant()
  const { getStructuresBySlugPath, getStructuresChildrens } = useStructures()
  const { gqlStructureQuery, gqlStructureChildrenQuery } = useTranslations()
  const { locale } = useI18n()
  const route = useRoute()
  const parentSlugPath = computed(
    () =>
      Array.isArray(route.params.slugPath) &&
      route.params.slugPath.slice(0, 2).join('/'),
  )
  const parent = computed(() => getStructuresBySlugPath(parentSlugPath.value))
  const archiveSlugPath = computed(
    () =>
      Array.isArray(route.params.slugPath) && route.params.slugPath.join('/'),
  )
  const archive = computed(() => getStructuresBySlugPath(archiveSlugPath.value))
  const items = computed(() =>
    getStructuresChildrens(archive.value.id, 'DIRECTORY'),
  )
  const loading = ref(true)
  const cardActions = {
    action: (child: StructuresNode) => {
      return `/s/${child.slugPath}`
    },
  }

  const loadContent = async () => {
    await gqlStructureQuery(parentSlugPath.value, locale.value, {})
    await gqlStructureQuery(archiveSlugPath.value, locale.value, {})
    await gqlStructureChildrenQuery(archiveSlugPath.value, locale.value, {
      limit: 100,
    })

    setBreadcrumbs([
      {
        name: parent.value.name,
        to: `/${parent.value.slug}`,
      },
      {
        name: archive.value.name,
      },
    ])
  }

  watch(locale, () => {
    loadContent()
  })

  onBeforeMount(async () => {
    clearBreadcrumbs()

    loadContent()

    setBreadcrumbs([
      {
        name: parent.value.name,
        to: `/${parent.value.slug}`,
      },
      {
        name: archive.value.name,
      },
    ])
    loading.value = false
  })
  useHead({
    title: computed(
      () =>
        `${archive.value.name}, ${parent.value.name} - ${tenant.value.name}`,
    ),
  })

  return {
    archive,
    cardActions,
    items,
    loading,
  }
}

export default useArchive
