import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArchiveWrapper = _resolveComponent("ArchiveWrapper")!

  return (_openBlock(), _createBlock(_component_ArchiveWrapper, {
    title: _ctx.archive.name,
    items: _ctx.items,
    "card-actions": _ctx.cardActions,
    type: "exercises"
  }, null, 8, ["title", "items", "card-actions"]))
}