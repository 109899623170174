<template>
  <div class="v-archive" :class="[`v-archive-${type}`]">
    <div class="v-archive__wrapper">
      <TheHeading class="v-archive__heading" level="h1">
        {{ title }}
      </TheHeading>
      <Breadcrumbs />
      <div v-if="items" class="v-archive__sections">
        <section class="v-archive__section">
          <div class="v-archive__items">
            <div v-for="item in items" :key="item.id" class="v-archive__item">
              <Card
                class="v-archive__card"
                :title="item.name"
                :item="item"
                :description="item.description"
                :image-id="item.cover"
                :actions="cardActions"
                has-button
                :button-text="$t('ARCHIVE_CARD_BUTTON_TEXT')"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Breadcrumbs from '@/components/Breadcrumbs'
import TheHeading from '@/components/TheHeading'
import Card from '@/components/Card'

export default defineComponent({
  name: 'ArchiveWrapper',

  components: {
    Breadcrumbs,
    TheHeading,
    Card,
  },

  props: {
    title: String,
    type: String,
    items: Array,
    cardActions: Object,
  },
})
</script>

<style lang="scss">
.v-archive__items {
  overflow: auto;
  margin: 2rem -0.5rem;

  @include bp(small) {
    display: flex;
    flex-wrap: wrap;
  }

  + div {
    margin-top: 0;
  }
}

.v-archive__item {
  display: block;
  margin: 0.5rem;

  @include bp(small) {
    width: calc(50% - 1rem);
  }

  @include bp(medium) {
    width: calc(33% - 1rem);
  }

  @include bp(1320px) {
    width: calc(25% - 1rem);
  }
}
</style>
