import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "aria-label": "Breadcrumb",
  class: "c-breadcrumbs"
}
const _hoisted_2 = { class: "c-breadcrumbs__list" }
const _hoisted_3 = {
  key: 1,
  "aria-current": "page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.breadcrumbs.length > 1)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "c-breadcrumbs__item",
              key: item.name
            }, [
              (item.to)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: "c-breadcrumbs__link",
                    to: item.to
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(item.name), 1))
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}